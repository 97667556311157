export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'data-insights',
      displayName: 'menu.data-insights',
      meta: {
        icon: 'dataset',
      },
    },
    {
      name: 'real-time',
      displayName: 'menu.real-time',
      meta: {
        icon: 'dynamic_form',
      },
    },
    {
      name: 'metrics-optimizer',
      displayName: 'menu.metrics-optimizer',
      meta: {
        icon: 'data_thresholding',
      },
    },
    {
      name: 'targeting-optimizer',
      displayName: 'menu.targeting-optimizer',
      meta: {
        icon: 'crisis_alert',
      },
    },
    // {
    //   name: 'trend-following',
    //   displayName: 'menu.trend-following',
    //   meta: {
    //     icon: 'data_thresholding',
    //   },
    // },
    // {
    //   name: 'refined-operations',
    //   displayName: 'menu.refined-operations',
    //   meta: {
    //     icon: 'diversity_1',
    //   },
    // },
    {
      name: 'comparison',
      displayName: 'menu.comparison',
      meta: {
        icon: 'dynamic_form',
      },
    },
    {
      name: 'traffic-analysis',
      displayName: 'menu.traffic-analysis',
      meta: {
        icon: 'hive',
      },
    },

    {
      name: 'message',
      displayName: 'menu.message',
      meta: {
        icon: 'mark_unread_chat_alt',
      },
    },
    {
      name: 'notification',
      displayName: 'menu.notification',
      meta: {
        icon: 'crisis_alert',
      },
    },
  ] as INavigationRoute[],
}
